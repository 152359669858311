<template>
  <v-app>
    <v-main>
      <a v-bind:href="homepageUrl">
        <div id="logo"></div>
      </a>
      <router-view class="router-view" />
      <CDNeedHelpButton />
      <CDFooter
          absolute
          fixed
          class="footer-page"
          copyright="© 2024 Bosch Digital Commerce GmbH. All rights reserved."
          :links="footerLinks"
      />
      <CDCookieBanner />
    </v-main>
  </v-app>
</template>

<style lang="scss">
@import "styles/app.scss";
</style>

<script>
import { sastLinks } from "./utils/enums";
import config from "../config";
import { VApp, VMain } from "vuetify/lib";

export default {
  name: "app",
  components: {
    VApp,
    VMain,
  },
  data() {
    return {
      sastLinks,
    };
  },
  computed: {
    homepageUrl() {
      return config().url;
    },
    footerLinks() {
      return [
        { text: this.$t("footerTerms"), url: sastLinks.TERMS_AND_CONDITIONS_URL },
        { text: this.$t("footerPrivacyPolicy"), url: sastLinks.PRIVACY_POLICY_URL },
        { text: this.$t("footerLegal"), url: sastLinks.LEGAL_URL },
        { text: this.$t("footerImprint"), url: sastLinks.IMPRINT_URL },
        { text: this.$t("footerReportInfringement"), url: sastLinks.REPORT_INFRIGNEMENT_URL }
      ];
    },
  },
};
</script>

<style>
footer {
  margin-top: auto;
}

.v-application--wrap {
  width: 100%;
}

.v-main__wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
